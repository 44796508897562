import { toBoolean } from "./format";

type Parameter = Exclude<ImportMetaEnv, null | undefined>;

declare global {
  interface Window {
    __ENV__: { [key: string]: string | undefined };
  }
}

const getParameters = (): Parameter => {
  const windowEnv = window.__ENV__;

  const appCues =
    windowEnv?.PARTNER_PORTAL_APPCUES_ENABLED == undefined
      ? toBoolean(import.meta.env.PARTNER_PORTAL_APPCUES_ENABLED)
      : toBoolean(windowEnv?.PARTNER_PORTAL_APPCUES_ENABLED);

  return {
    DEV: import.meta.env.DEV as boolean,
    PROD: import.meta.env.PROD as boolean,
    MODE: import.meta.env.MODE as string,
    BASE_URL: import.meta.env.BASE_URL as string,
    SSR: import.meta.env.SSR as boolean,
    PARTNER_PORTAL_VERSION: import.meta.env.PARTNER_PORTAL_VERSION as string,
    PARTNER_PORTAL_RC_VERSION: import.meta.env.PARTNER_PORTAL_RC_VERSION as string,
    PARTNER_PORTAL_ENV: windowEnv?.PARTNER_PORTAL_ENV ?? import.meta.env.PARTNER_PORTAL_ENV,
    PARTNER_PORTAL_DATA_DOG_APP_ID:
      windowEnv?.PARTNER_PORTAL_DATA_DOG_APP_ID ?? import.meta.env.PARTNER_PORTAL_DATA_DOG_APP_ID,
    PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN:
      windowEnv?.PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN ?? import.meta.env.PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN,
    PARTNER_PORTAL_API_SERVER_URL:
      windowEnv?.PARTNER_PORTAL_API_SERVER_URL ?? import.meta.env.PARTNER_PORTAL_API_SERVER_URL,
    PARTNER_PORTAL_APPCUES_ENABLED: appCues ?? false,
  };
};

const ENV_PARAMS: Parameter = getParameters();

export default ENV_PARAMS;
