import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import ENV_PARAMS from "utils/environment";
import { getVersion } from "./version";

const bootstrapDatadog = () => {
  const version = getVersion();
  datadogLogs.init({
    clientToken: ENV_PARAMS.PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "partner-portal-client",
    env: ENV_PARAMS.PARTNER_PORTAL_ENV,
    version: version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 0,
  });

  datadogRum.init({
    applicationId: ENV_PARAMS.PARTNER_PORTAL_DATA_DOG_APP_ID,
    clientToken: ENV_PARAMS.PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "partner-portal-client",
    env: ENV_PARAMS.PARTNER_PORTAL_ENV,
    version: version,
    sessionSampleRate: 0,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: "allow",
  });

  datadogRum.startSessionReplayRecording();
};

export default bootstrapDatadog;
